import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { AppTypography } from "components/common";
import { TableRow } from "components/common/TableResult";
import { RoundContainer } from "components/sn-round";
import { KeyAbleProps } from "models/types";
import { CandyService } from "services";
import { formatISO } from "date-fns";
import { ApiConstant, AppConstant } from "const";
import { useCandyMachineContext } from "context";
import { get } from "lodash";
import { DefaultCandyGuardSettings } from "@metaplex-foundation/js";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

const OGPage = () => {
  const { candyMachine } = useCandyMachineContext();
  const candyGuard = get(candyMachine, "candyGuard.groups[0]", {}) as {
    guards: DefaultCandyGuardSettings;
    label: string;
  };

  const [data, setData] = useState<RoundProps>({ ...INIT_DATA, limitAmount: 1 });

  const handleUpdateData = (newData: KeyAbleProps) => {
    setData((prev) => ({ ...prev, ...newData }));
  };

  const handleSubmit = async () => {
    if (!data?.start || !data?.end) return false;

    const newData = {
      groupId: AppConstant.ROUND_TYPE.OG,
      label: data.label,
      start: formatISO(data.start),
      end: formatISO(data.end),
      limitAmount: Number(data.limitAmount),
      price: Number(data.price),
    };

    const response = await CandyService.updateRound(newData);

    return response.status === ApiConstant.STT_OK;
  };

  useEffect(() => {
    if (!candyGuard.guards) return;

    const newData = getCandyGuardData(candyGuard.guards);

    setData({ ...newData, label: candyGuard.label });
  }, [candyMachine]);

  return (
    <Box mb={18} mt={4.5} px={7.5}>
      <Stack direction="row" justifyContent="space-between" mb={6}>
        <AppTypography
          sx={{
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
          }}
        >
          {`${candyGuard.label ?? ""} Round Settings`}
        </AppTypography>
      </Stack>
      <RoundContainer
        roundType={AppConstant.ROUND_TYPE.OG}
        data={data}
        onChange={handleUpdateData}
        onSubmit={handleSubmit}
      />
    </Box>
  );
};

export default OGPage;

export const INIT_DATA = {
  label: "",
  start: null,
  end: null,
  price: null,
  limitAmount: null,
};

export type RoundProps = {
  label: string;
  start: number | null;
  end: number | null;
  price: number | null;
  limitAmount: number | null;
  walletList?: TableRow[];
};

export const getCandyGuardData = (candyGuard: DefaultCandyGuardSettings) => {
  const startDate = candyGuard?.startDate?.date;
  const endDate = candyGuard?.endDate?.date;
  const price = candyGuard?.solPayment?.amount?.basisPoints;
  const limit = candyGuard?.mintLimit?.limit;

  if (!startDate || !endDate || !price) return INIT_DATA;

  return {
    start: startDate.toNumber() * 1000,
    end: endDate.toNumber() * 1000,
    price: price.toNumber() / LAMPORTS_PER_SOL,
    limitAmount: limit ?? null,
  };
};

export const KEY_TOKEN = "token";

export const NOT_HAVE_VALUE_LABEL = "- -";
export const NOT_AVAILABLE_VALUE = "N/A";

export const SIZE_PAGINATION_DEFAULT = 5;
export const DEFAULT_PAGINATION = {
  page: 1,
  size: SIZE_PAGINATION_DEFAULT,
};
export const SORT_DIRECTION = {
  asc: 1,
  desc: -1,
};

export const DEFAULT_LANGUAGE = [{ value: "en", label: "English" }];

// Date, Time Format
export const DATE_FORMAT = "dd/MM/yy";
export const FULL_DATE_FORMAT = "dd/MM/yyyy";
export const TIME_FORMAT = "HH:mm";
export const DEFAULT_TIMEZONE = "+07:00";

export const MSG_SOMETHING_WENT_WRONG = "Something went wrong, please try again later";

export const ROW_PER_PAGE_OPTIONS = [5, 10, 15];
export const LIMIT_PAGINATION_EXPORT_CSV = 1000;

export enum ROUND_TYPE {
  OG,
  WHITELIST,
  PUBLIC,
}

export enum STATUS_MENU {
  ALL = "",
  SEND = "$not:$null",
  NOT_SEND = "$null",
}

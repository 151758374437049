import React, { useState, useEffect } from "react";
import TableResult, { TableRow } from "components/common/TableResult";
import { makeStyles } from "@mui/styles";
import { ThemeProps } from "models/types";
import { AppButton, UploadButton, AppDialog } from "components/common";
import { Stack } from "@mui/material";
import { useAuthContext } from "context";
import { AppConstant } from "const";

const UploadFileCSV = ({ onConfirm }: { onConfirm: (file: File) => Promise<void> }) => {
  const classes = useStyles();
  const { setIsFetching } = useAuthContext();

  const [csvData, setCsvData] = useState<TableRow[]>([]);
  const [tableRows, setTableRows] = useState<TableRow[]>([]);
  const [isShowDialog, setIsShowDialog] = useState(false);
  const [pageSize, setPageSize] = useState(AppConstant.SIZE_PAGINATION_DEFAULT);
  const [currentPage, setCurrentPage] = useState(0);
  const [file, setFile] = useState<File>();

  const handleSetCsvData = (data: string[][], file: File) => {
    if (Array.isArray(data) && file) {
      const newState: TableRow[] = [];
      data.shift();
      data.forEach((rows) => newState.push([...rows]));

      setTableRows(data.slice(0, AppConstant.SIZE_PAGINATION_DEFAULT));
      setCsvData(newState);
      setIsShowDialog(true);
      setFile(file);
    }
  };

  const handleAddingUserFromCSV = async () => {
    if (!file) return;

    handleCloseDialog();
    setIsFetching(true);
    await onConfirm(file);
  };

  const handleCloseDialog = () => {
    setTableRows([]);
    setIsShowDialog(false);
    setPageSize(AppConstant.SIZE_PAGINATION_DEFAULT);
    setCurrentPage(0);
  };

  useEffect(() => {
    const startIndex = currentPage * pageSize;
    const newTableRows = csvData.slice(startIndex, startIndex + pageSize);

    setTableRows(newTableRows);
  }, [currentPage, pageSize, csvData]);

  return (
    <>
      <UploadButton onUploadSuccess={handleSetCsvData} onUploadError={console.log}>
        Upload CSV
      </UploadButton>
      <AppDialog
        open={isShowDialog}
        onClose={handleCloseDialog}
        classes={{
          paper: classes.paper,
        }}
        dialogTitle="List Wallet Address"
        dialogContent={
          <>
            <TableResult
              TablePaginationProps={{
                page: currentPage,
                rowsPerPage: pageSize,
                count: csvData.length,

                onPageChange: (_event, page) => setCurrentPage(page),
                onRowsPerPageChange: (
                  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                ) => {
                  setCurrentPage(0);
                  setPageSize(Number(event.target.value));
                },
              }}
              tableHeader={TABLE_HEADER}
              tableBodyData={tableRows}
            />
            <Stack
              sx={{
                float: "right",
              }}
              mt={1.5}
              direction="row"
              spacing={4}
            >
              <AppButton
                className={classes.cancelButton}
                onClick={() => {
                  setIsShowDialog(false);
                }}
              >
                Cancel
              </AppButton>
              <AppButton className={classes.saveButton} onClick={handleAddingUserFromCSV}>
                Save
              </AppButton>
            </Stack>
          </>
        }
        dialogTitleProps={{
          className: classes.customDialogTitle,
        }}
        dialogContentProps={{
          className: classes.customDialogContent,
        }}
      />
    </>
  );
};

export default UploadFileCSV;

export const TABLE_HEADER = ["No", "Wallet Address"];

const useStyles = makeStyles((theme: ThemeProps) => ({
  customDialogTitle: {
    textAlign: "left",
    paddingBottom: theme.spacing(5),
  },
  customDialogContent: {
    width: 752,
  },
  saveButton: {
    background: "linear-gradient(90deg, #32B44A 0%, #007F3F 100%);",
    borderRadius: 4,
    height: 40,
    minWidth: 124,
  },
  paper: {
    maxWidth: 833,
  },
  cancelButton: {
    background: "#A93131",
    borderRadius: 4,
    height: 40,
    minWidth: 124,

    "&:hover": {
      background: "#A93131",
    },
  },
}));

import React, { useMemo } from "react";
import SidebarListItem from "./SidebarListItem";
import { CardMedia, List, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ImageAssets } from "assets";
import { AppButton, AppLink } from "components/common";
import { LogoutIcon } from "components/icons";
import { AppConstant, PathConstant } from "const";
import { useAuthContext, useCandyMachineContext } from "context";
import { ThemeProps } from "models/types";

const SidebarList = () => {
  const classes = useStyles();
  const { handleLogout } = useAuthContext();

  const { candyMachine } = useCandyMachineContext();

  const sideBarList = useMemo(() => {
    if (!candyMachine?.candyGuard?.groups) return [];

    const roundList = candyMachine.candyGuard.groups.map((item, index) => {
      switch (index) {
        case AppConstant.ROUND_TYPE.OG:
          return {
            label: item.label,
            path: PathConstant.OG,
          };
        case AppConstant.ROUND_TYPE.WHITELIST:
          return {
            label: item.label,
            path: PathConstant.WHITELIST,
          };

        case AppConstant.ROUND_TYPE.PUBLIC:
        default:
          return {
            label: item.label,
            path: PathConstant.PUBLIC,
          };
      }
    });

    return roundList;
  }, [candyMachine]);

  return (
    <>
      <AppLink href={PathConstant.ROOT} className={classes.logoContainer}>
        <CardMedia component="img" src={ImageAssets.LogoFullImage} className={classes.logo} />
      </AppLink>
      <List className={classes.list}>
        {sideBarList.map(({ label, path }, index) => (
          <SidebarListItem key={index} path={path}>
            <ListItemText primary={label} />
          </SidebarListItem>
        ))}
        <AppButton
          startIcon={<LogoutIcon sx={{ fontSize: 20 }} />}
          className={classes.buttonLogout}
          onClick={handleLogout}
        >
          Logout
        </AppButton>
      </List>
    </>
  );
};

export default SidebarList;

const useStyles = makeStyles((theme: ThemeProps) => ({
  toolbar: {
    minHeight: "60px",
  },
  list: {
    padding: 0,
  },
  buttonLogout: {
    width: "185px",
    height: "40px",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    margin: theme.spacing(3, 2),
    color: "white",
    background: "#007F3F",
    borderRadius: 16,
    justifyContent: "flex-start",

    "& span": {
      marginRight: "11px",
      marginLeft: "5px",
    },

    "&:hover": {
      background: "#007F3F",
    },
  },
  logo: {
    display: "block",
    width: 160,
    height: 34,
    objectFit: "contain",
  },
  logoContainer: {
    position: "relative",
    display: "block",
    width: "100%",
    padding: 30,
    height: 102,
    marginBottom: 12,
  },
}));

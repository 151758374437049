import React from "react";
import { Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { RotatingLines } from "react-loader-spinner";
import AppTypography from "./AppTypography";

const AppLoading = () => {
  const classes = useStyles();

  return (
    <Stack className={classes.root} spacing={6.25}>
      <Stack alignItems="center" spacing={7}>
        <RotatingLines
          strokeColor="#3DEB59"
          strokeWidth="4"
          animationDuration="0.75"
          width="226"
          visible={true}
        />
        <AppTypography
          sx={{
            fontWeight: 700,
            fontSize: 48,
            lineHeight: "24px",
            color: "white",
          }}
        >
          Please Wait...
        </AppTypography>
      </Stack>
    </Stack>
  );
};

export default AppLoading;

const useStyles = makeStyles(() => ({
  root: {
    position: "fixed",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 100000,
    userSelect: "none",
    background: "rgba(34, 34, 34, 0.7)",
  },
}));

import { createApi, createFileApi } from "api";
import { ApiConstant, AppConstant } from "const";

type RequestRoundProps = {
  groupId: AppConstant.ROUND_TYPE;
  label: string;
  start: string;
  end: string;
  price: number;
  limitAmount?: number;
};

export type GetWhitelistProps = {
  groupId: AppConstant.ROUND_TYPE;
  page: number;
  limit?: number;
  "filter.hash"?: string;
};

export const updateRound = (data: RequestRoundProps) =>
  createApi().post(ApiConstant.POST_UPDATE_ROUND, data);

export const addWhitelist = (data: FormData) =>
  createFileApi().post(ApiConstant.POST_ADD_WHITELIST, data);

export const getWhitelist = (data: GetWhitelistProps) =>
  createApi().get(ApiConstant.GET_WHITELIST, data);

export const resendToken = () => 
  createApi().post(ApiConstant.RESEND_TOKEN);
import React, { cloneElement, ReactElement } from "react";
import { Stack } from "@mui/material";
import AppTypography from "./AppTypography";

const AppLabel = ({ label, content }: { label: string; content: ReactElement<any> }) => {
  const ClonedContent = cloneElement(content, {
    sx: {
      width: "100%",
      height: 40,
      color: "#222423",

      "& .MuiInputBase-input": {
        padding: "8px 12px",
        height: 24,
        color: "#222423",
        fontWeight: 400,
        fontSize: 16,
        lineHeight: "24px",
      },

      "& .MuiInputLabel-root": {
        transform: "translate(14px, 8px) scale(1);",
      },

      "& .MuiInputLabel-root.MuiFormLabel-filled, .MuiInputLabel-shrink": {
        transform: "translate(14px, -9px) scale(0.75)",
      },
    },
  });

  return (
    <Stack alignItems="center" direction="row" sx={{ maxWidth: 608 }} spacing={3}>
      <AppTypography width={116} variant="caption" color="#424242" fontSize={16}>
        {label}
      </AppTypography>
      {ClonedContent}
    </Stack>
  );
};

export default AppLabel;

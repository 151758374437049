import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { AppTypography } from "components/common";
import { KeyAbleProps } from "models/types";
import { RoundContainer } from "components/sn-round";
import { TableRow } from "components/common/TableResult";
import { getCandyGuardData, INIT_DATA, RoundProps } from "./OGPage";
import { formatISO } from "date-fns";
import { CandyService } from "services";
import { ApiConstant, AppConstant } from "const";
import { get } from "lodash";
import { useCandyMachineContext } from "context";
import { DefaultCandyGuardSettings } from "@metaplex-foundation/js";

const WhitelistPage = () => {
  const { candyMachine } = useCandyMachineContext();
  const candyGuard = get(candyMachine, "candyGuard.groups[1]", {}) as {
    guards: DefaultCandyGuardSettings;
    label: string;
  };

  const [data, setData] = useState<RoundProps>({ ...INIT_DATA, limitAmount: 5 });

  const handleUpdateData = (newData: KeyAbleProps) => {
    setData((prev) => ({ ...prev, ...newData }));
  };

  const handleSubmit = async () => {
    if (!data?.start || !data?.end) return false;

    const newData = {
      groupId: AppConstant.ROUND_TYPE.WHITELIST,
      label: data.label,
      start: formatISO(data.start),
      end: formatISO(data.end),
      limitAmount: Number(data.limitAmount),
      price: Number(data.price),
    };
    const response = await CandyService.updateRound(newData);

    return response.status === ApiConstant.STT_OK;
  };

  const handleUploadCsvSuccess = (data: TableRow[]) => {
    handleUpdateData({ walletList: data });
  };

  useEffect(() => {
    if (!candyGuard.guards) return;

    const newData = getCandyGuardData(candyGuard.guards);
    setData({ ...newData, label: candyGuard.label });
  }, [candyMachine]);

  return (
    <Box mb={18} mt={4.5} px={7.5}>
      <Stack direction="row" justifyContent="space-between" mb={6}>
        <AppTypography
          sx={{
            fontWeight: 700,
            fontSize: 24,
            lineHeight: "32px",
          }}
        >
          {`${candyGuard.label ?? ""} Round Settings`}
        </AppTypography>
      </Stack>
      <RoundContainer
        roundType={AppConstant.ROUND_TYPE.WHITELIST}
        data={data}
        onChange={handleUpdateData}
        onSubmit={handleSubmit}
        onUploadCsvSuccess={handleUploadCsvSuccess}
      />
    </Box>
  );
};

export default WhitelistPage;

const white = "#FFFFFF";
const black = "#000000";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TPalette = Record<string, any> & { mode: "light" | "dark" };

const palette: TPalette = {
  mode: "light",
  common: {
    main: "#32B44A",
    black,
    white,
  },
  primary: {
    main: "#32B44A",
    dark: "#007F3F",
    light: "#00A249",
    contrastText: white,
  },
  secondary: {
    main: "#5CD3BB",
    light: "#007F3F",
  },
  error: {
    main: "#F5222D",
    light: "#FF9F9F",
    dark: "#F5222D",
    contrastText: white,
  },
  warning: {
    main: "#FADB14",
    light: "#ff9800",
    dark: "#D3B239",
    contrastText: white,
  },
  success: {
    main: "#52C41A",
    light: "#4caf50",
    dark: "#1b5e20",
    contrastText: white,
  },
  grey: {
    5: "rgba(0, 0, 0, 0.04)",
    10: "rgba(0, 0, 0, 0.16)",
    20: "rgba(0, 0, 0, 0.4)",
    30: "rgba(0, 0, 0, 0.48)",
    40: "rgba(255, 255, 255, 0.12)",
    50: "rgba(255, 255, 255, 0.16)",
    55: "rgba(255, 255, 255, 0.24)",
    60: "rgba(0, 0, 0, 0.6)",
    70: "#1A1F27",
    80: "rgba(255, 255, 255, 0.06)",
    100: "rgba(92, 211, 187, 0.5)",
    200: "rgba(100, 188, 171, 0.5)",
    300: "#222423",
    500: "#4A5665",
  },
  text: {
    primary: black,
    secondary: "#A9B5B3",
    disabled: "rgba(255, 255, 255, 0.6)",
  },
  background: {
    default: "linear-gradient(90deg, #32B44A 0%, #007F3F 100%)",
    primary: "linear-gradient(180deg, #042B17 0%, rgba(4, 43, 23, 0) 100%)",
    secondary: "linear-gradient(198.72deg, #1C633F 6.49%, rgba(11, 39, 25, 0) 51.2%);",
    tertiary: "linear-gradient(180deg, #1C633F 0%, rgba(23, 35, 29, 0) 100%)",
    paper: "rgba(255,255,255)",
  },
  modal: {
    paper: "#12094A",
    title: "#201566",
    content: "#232527",
    success: "#FFCC6A",
    background: "#FFEDCB",
  },
  gradient: {
    primary: "linear-gradient(90deg, #32B44A 0%, #007F3F 100%)",
    secondary: "linear-gradient(180deg, #042B17 0%, rgba(4, 43, 23, 0) 135.17%)",
    hover: "linear-gradient(3.65deg, #FF930D -17.41%, #FFC803 79.3%)",
    disabled: "linear-gradient(3.65deg, #6B6B6B -17.41%, #B1B1B1 78.55%)",
    modalConfirm: "linear-gradient(0deg, #D8F9F3, #D8F9F3), #FFFFFF",
    border: "linear-gradient(180deg, #FFFFFF 0%, #35B34A 100%)",
  },
  shadow: {
    disabled: "#565861",
    primary: "#2D49A0",
    secondary: "#8E5D24",
    light: "#d4dad8",
  },
  disabled: {
    primary: "#9F9F9F",
    secondary: "#2D2D2D",
    border: "rgba(45, 45, 45, 0.1)",
    text: "#626262",
  },
};

export default palette;

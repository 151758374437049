import React, { useState } from "react";
import { Menu, MenuItem, TextField } from "@mui/material";
import { AppButton } from "components/common";
import { STATUS_MENU } from "const/app.const";
import { makeStyles } from "@mui/styles";
import { ThemeProps } from "models/types";
import { ArrowIcon } from "components/icons";

const StatusMenu = ({
  onChange,
  options,
}: {
  onChange: (selectedData: { label: string; value: STATUS_MENU,}) => void;
  options: {label: string, value: STATUS_MENU}[]
}) => {
  const classes = useStyles();

  const [selectedData, setSelectedData] = useState(options[0]);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  return (
    <>
      <AppButton
        endIcon={<ArrowIcon />}
        className={classes.label}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        {selectedData.label}
      </AppButton>
      <Menu onClose={() => setAnchorEl(null)} open={!!anchorEl} anchorEl={anchorEl}>
        {options.map((item, index) => (
          <MenuItem
            sx={{
              fontWeight: 400,
              fontSize: 16,
              lineHeight: "24px",
            }}
            onClick={() => {
              onChange(item);
              setSelectedData(item);
              setAnchorEl(null);
            }}
            key={index}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default StatusMenu;

const useStyles = makeStyles((theme: ThemeProps) => ({
  label: {
    background: "transparent",
    color: theme.palette.common.black,
    height: 32,
    minWidth: 100,
    border: "1px solid #CDCCCC",
    boxShadow: "0px 0.5px 0px #E2E0E0",
    borderRadius: 12,
    fontWeight: 400,
    fontSize: 16,
    lineHeight: "24px",

    "&:hover": {
      background: "transparent",
      color: theme.palette.common.black,
      boxShadow: "none",
    },
  },
}));

export const MENU_DATA = [
  {
    label: "All",
    value: STATUS_MENU.ALL,
  },
  {
    label: "Send",
    value: STATUS_MENU.SEND,
  },
  {
    label: "Not send",
    value: STATUS_MENU.NOT_SEND,
  },
];
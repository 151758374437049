import { createApi } from "api";
import { ApiConstant } from "const";

export interface ILogin {
  username: string;
  password: string;
}

export const login = (data: ILogin) => createApi().post(ApiConstant.POST_LOGIN, data);

export const profile = () => createApi().get(ApiConstant.GET_PROFILE);

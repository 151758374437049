import apisauce, { ApiResponse, ApisauceConfig, ApisauceInstance } from "apisauce";
import { ApiConstant, AppConstant, EnvConstant, PathConstant } from "const";
import Cookies from "js-cookie";

const DEFAULT_CONFIG: ApisauceConfig = {
  baseURL: "",
  headers: { ...ApiConstant.HEADER_DEFAULT },
  timeout: ApiConstant.TIMEOUT,
};

const FORM_CONFIG: ApisauceConfig = {
  baseURL: "",
  headers: { ...ApiConstant.HEADER_FILE },
  timeout: ApiConstant.TIMEOUT,
};

const handleErrorRequest = (response: ApiResponse<IApiResponse>) => {
  if ([ApiConstant.STT_UNAUTHORIZED, ApiConstant.STT_FORBIDDEN].includes(response.status || 0)) {
    Cookies.remove(AppConstant.KEY_TOKEN);

    if (window.location.pathname === PathConstant.LOGIN) return;
    window.location.replace(PathConstant.LOGIN);
  }

  if (
    response.status &&
    false === [ApiConstant.STT_OK, ApiConstant.STT_CREATED].includes(response.status)
  ) {
    EnvConstant.IS_DEV && console.log(response);
  }
};

const createInstance = (api: ApisauceInstance, baseURL?: string, token?: string) => {
  const newToken = token || Cookies.get(AppConstant.KEY_TOKEN);

  baseURL && api.setBaseURL(baseURL);
  newToken && api.setHeader("Authorization", `Bearer ${newToken}`);

  return api;
};

const Api = apisauce.create(DEFAULT_CONFIG);
const FileApi = apisauce.create(FORM_CONFIG);

Api.addResponseTransform(handleErrorRequest);
FileApi.addResponseTransform(handleErrorRequest);

export const createApi = (token?: string) => createInstance(Api, EnvConstant.BASE_URL, token);

export const createFileApi = (token?: string) =>
  createInstance(FileApi, EnvConstant.BASE_URL, token);

export default Api;

export interface IApiResponse {
  status: number;
  data: object;
}

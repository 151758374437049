/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { createContext, useContext, useEffect, useState } from "react";
import { CandyMachine, Metaplex, DefaultCandyGuardSettings } from "@metaplex-foundation/js";
import { Cluster, clusterApiUrl, Connection, PublicKey } from "@solana/web3.js";
import { EnvConstant } from "const";
import { useAuthContext } from "./AuthContext";
import { toast } from "react-toastify";
import { isNumber } from "lodash";

const INITIAL_STATE = {
  candyMachine: {},
  getCandyMachine: async () => {
    return {};
  },
} as CandyMachineContextProps;

const CandyMachineContext = createContext(INITIAL_STATE);
export const useCandyMachineContext = (): CandyMachineContextProps =>
  useContext(CandyMachineContext);

export const CandyMachineProvider = ({ children }: CandyMachineProvider): JSX.Element => {
  const { isAuthenticated, setIsFetching, isFetching } = useAuthContext();

  const candyMachineId = new PublicKey(EnvConstant.CANDY_MACHINE_ID!);
  const connection = new Connection(EnvConstant.RPC_URL || clusterApiUrl((EnvConstant.CHAIN_NETWORK! || "devnet") as Cluster), 'confirmed');
  const metaplex = Metaplex.make(connection);

  const [candyMachine, setCandyMachine] = useState(INITIAL_STATE.candyMachine);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [clientSubscriptionId, setClientSubscriptionId] = useState<number | null>(null);

  const handleAddListener = async () => {
    if (!candyMachine?.candyGuard || clientSubscriptionId || !isFetching) return;
    // add a listener to monitor changes to the candy guard
    const data = metaplex.connection.onAccountChange(candyMachine.candyGuard.address, () => {
      setIsUpdateSuccess(true);
      setIsFetching(false);
      return handleGetCandyMachine();
    });

    setClientSubscriptionId(data);
  };

  const handleGetCandyMachine = async () => {
    const newCandyMachine = await metaplex
      .candyMachines()
      .findByAddress({ address: candyMachineId });

    setCandyMachine(newCandyMachine);

    return newCandyMachine;
  };

  useEffect(() => {
    if (!isAuthenticated) return;

    handleGetCandyMachine();
  }, [isAuthenticated]);

  useEffect(() => {
    handleAddListener();

    return () => {
      if (isNumber(clientSubscriptionId)) {
        metaplex.connection.removeAccountChangeListener(clientSubscriptionId as number);
        setClientSubscriptionId(null);
      }
    };
  }, [candyMachine?.candyGuard, clientSubscriptionId, isFetching]);

  useEffect(() => {
    if (isUpdateSuccess && !isFetching) {
      toast.success("Successfully!");
      setIsUpdateSuccess(false);
    }
  }, [isFetching, isUpdateSuccess]);

  return (
    <CandyMachineContext.Provider
      value={{
        candyMachine,
        isUpdateSuccess,
        setIsUpdateSuccess,
        getCandyMachine: handleGetCandyMachine,
      }}
    >
      {children}
    </CandyMachineContext.Provider>
  );
};

type CandyMachineProvider = {
  children: React.ReactNode;
};

export type CandyMachineProps = CandyMachine<DefaultCandyGuardSettings>;

export type CandyMachineContextProps = {
  candyMachine: CandyMachineProps;
  isUpdateSuccess: boolean;
  setIsUpdateSuccess: (isUpdateSuccess: boolean) => void;
  getCandyMachine: () => Promise<CandyMachine<DefaultCandyGuardSettings>>;
};

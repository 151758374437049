import React, { ComponentPropsWithoutRef, FC, ChangeEvent, HTMLAttributes } from "react";
import { AppButton } from "components/common";
import { KeyAbleProps } from "models/types";
import Papa from "papaparse";

const UploadButton: FC<UploadButtonProps> = ({
  onUploadSuccess,
  onUploadError,
  inputProps = {},
  children,
  ...otherProps
}) => {
  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    const nativeEvent = event.nativeEvent.target as HTMLInputElement;
    const targetEvent = event.target as HTMLInputElement;

    if (targetEvent.files && targetEvent.files[0]) {
      const uploadFile = targetEvent.files[0];
      Papa.parse(uploadFile, {
        complete: ({ data }: KeyAbleProps) => {
          if (onUploadSuccess) onUploadSuccess(data, uploadFile);
        },
        error: (err: any) => {
          if (onUploadError) onUploadError(err);
        },
      });
      nativeEvent.value = "";
    }
  };

  return (
    <AppButton component="label" {...otherProps}>
      {children}
      <input accept=".csv" {...inputProps} type="file" onChange={handleUploadFile} hidden />
    </AppButton>
  );
};

export default UploadButton;

interface UploadButtonProps extends ComponentPropsWithoutRef<typeof AppButton> {
  onUploadSuccess?: (data: string[][], uploadFile: File) => void;
  onUploadError?: (error: Error) => void;
  inputProps?: HTMLAttributes<HTMLInputElement>;
}

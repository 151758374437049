import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PathConstant } from "const";
import { LoginPage, OGPage, PublicPage, WhitelistPage } from "pages";
import { AppLoading, AuthenticationRoute } from "components/common";
import { useAuthContext } from "context";
import MainLayout from "layouts/MainLayout";

const App = () => {
  const { isFetching } = useAuthContext();

  return (
    <>
      {isFetching && <AppLoading />}

      <Routes>
        <Route path={PathConstant.LOGIN} element={<LoginPage />} />
        <Route element={<AuthenticationRoute element={<MainLayout />} />}>
          <Route path={PathConstant.ROOT} element={<Navigate to={PathConstant.OG} replace />} />
          <Route path={PathConstant.OG} element={<OGPage />} />
          <Route path={PathConstant.WHITELIST} element={<WhitelistPage />} />
          <Route path={PathConstant.PUBLIC} element={<PublicPage />} />
          <Route />
        </Route>
      </Routes>
    </>
  );
};

export default App;

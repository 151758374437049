/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Context, createContext, ReactNode, useContext, useEffect, useState } from "react";
import { ApiConstant, AppConstant, PathConstant } from "const";
import { KeyAbleProps } from "models/types";
import { useNavigate } from "react-router-dom";
import { UserService } from "services";
import { ILogin } from "services/user.service";
import Cookies from "js-cookie";

/* ------------- Initial State ------------- */
const INITIAL_STATE = {
  isAuthenticated: false,
  isFetching: false,
  setIsFetching: (_: boolean) => {
    return;
  },
  handleLogin: (
    _data: ILogin,
    _isSaveLogin: boolean,
    _onSuccess: () => void,
    _onInvalid: () => void,
  ) => {
    return;
  },
  handleLogout: () => {
    return;
  },
};

export const AuthContext: Context<IAuthContextProps> = createContext(INITIAL_STATE);
export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const hasLogin = Boolean(Cookies.get(AppConstant.KEY_TOKEN));

  const [isAuthenticated, setIsAuthenticated] = useState(hasLogin);
  const [isFetching, setIsFetching] = useState(false);

  const handleLogin = async (
    data: ILogin,
    isSaveLogin: boolean,
    onSuccess: () => void,
    onInvalid: () => void,
  ) => {
    const responseLogin: KeyAbleProps = await UserService.login(data);
    switch (responseLogin.status) {
      case ApiConstant.STT_CREATED:
        setIsAuthenticated(true);
        handleSaveAccessToken(isSaveLogin, responseLogin.data?.access_token);
        onSuccess();
        break;
      default:
        onInvalid();
        break;
    }
  };

  const handleLogout = async () => {
    Cookies.remove(AppConstant.KEY_TOKEN);
    setIsAuthenticated(false);
    return;
  };

  const handleGetUserInfo = async () => {
    const response = await UserService.profile();

    if (response.status === ApiConstant.STT_OK) {
      navigate(PathConstant.ROOT, { replace: true });
    }
  };

  useEffect(() => {
    const tokenCookie = Cookies.get(AppConstant.KEY_TOKEN);

    if (!tokenCookie) return;
    handleGetUserInfo();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isFetching,
        setIsFetching,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const handleSaveAccessToken = (isSaveLogin: boolean, token: string) => {
  if (isSaveLogin) {
    Cookies.set(AppConstant.KEY_TOKEN, token, { expires: 365 });
  } else {
    Cookies.set(AppConstant.KEY_TOKEN, token);
  }
};

export type IAuthContextProps = {
  isAuthenticated: boolean;
  isFetching: boolean;
  setIsFetching: (isFetching: boolean) => void;
  handleLogin: (
    data: ILogin,
    isSaveLogin: boolean,
    onSuccess: () => void,
    onInvalid: () => void,
  ) => void;
  handleLogout: () => void;
};

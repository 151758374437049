import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Box, ButtonClasses, Stack } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MobileDateTimePicker } from "@mui/x-date-pickers";
import { ApiConstant, AppConstant } from "const";
import { KeyAbleProps } from "models/types";
import { RoundProps } from "pages/OGPage";
import { AppButton, AppInput, AppLabel, AppTypography, UploadFileCSV } from "components/common";
import { getUnixTime } from "date-fns";
import { CandyService } from "services";
import { toast } from "react-toastify";
import { useAuthContext } from "context";
import TableResult, { TableRow } from "components/common/TableResult";
import StatusMenu, { MENU_DATA } from "./StatusMenu";
import { GetWhitelistProps } from "services/candy.service";
import ResendButton from "components/common/ResendButton";

const RoundContainer = ({ roundType, data, onChange, onSubmit }: RoundContainerProps) => {
  const classes = useStyles();
  const { setIsFetching } = useAuthContext();

  const [tableRows, setTableRows] = useState<ReactNode[][]>([]);
  const [selectedStatus, setSelectedStatus] = useState(MENU_DATA[0]);

  const [pagination, setPagination] = useState({
    pageSize: AppConstant.SIZE_PAGINATION_DEFAULT,
    currentPage: 0,
    totalItems: 0,
  });

  const isPublicRound = useMemo(() => roundType === AppConstant.ROUND_TYPE.PUBLIC, [roundType]);

  const isDisabledSubmitButton = useMemo(() => {
    const clonedData: KeyAbleProps = { ...data };
    if (isPublicRound) delete clonedData.limitAmount;

    const isInValidLabel = data.label.length > 6;
    if (isInValidLabel) return true;

    const startDate = getUnixTime(clonedData.start);
    const endDate = getUnixTime(clonedData.end);

    if (startDate >= endDate) return true;

    const isDisabled = Object.values(clonedData).some((item) => item !== 0 && !item);

    return isDisabled;
  }, [data, isPublicRound]);

  const handleChangePagination = (data: KeyAbleProps) => {
    setPagination((prev) => ({ ...prev, ...data }));
  };

  const handleSetWhitelist = async (file: File) => {
    const params = new FormData();
    params.append("groupId", roundType.toString());
    params.append("file", file);
    const response = await CandyService.addWhitelist(params);

    if (response.status === ApiConstant.STT_CREATED) {
      setTimeout(() => {
        setIsFetching(false);
        toast.success("Successfully!");
        handleGetWhitelistAddress();
      }, 1000);
    } else {
      setTimeout(() => {
        setIsFetching(false);
        toast.error("Upload file failed!");
      }, 1000);
    }
  };

  const handleGetWhitelistAddress = async () => {
    const params: GetWhitelistProps = {
      groupId: roundType,
      page: pagination.currentPage + 1,
      limit: pagination.pageSize,
      "filter.hash": selectedStatus.value,
    };

    if (!params["filter.hash"]) delete params["filter.hash"];

    const response = await CandyService.getWhitelist(params);

    if (response.status === ApiConstant.STT_OK) {
      const responseData = (response.data || {}) as ResponseWhitelistProps;

      if (!responseData?.data) return;

      const newTableRows = responseData.data.map((item) => {
        const status = item?.hash ? (
          <AppTypography color="#01A61B">{WhitelistStatus.SEND}</AppTypography>
        ) : (
          <AppTypography variant="body1" fontWeight={400} color="#A93131">
            {WhitelistStatus.NOT_SEND}
          </AppTypography>
        );
        return [item?.id, item?.wallet, status];
      });
      setTableRows(newTableRows);
      handleChangePagination({ totalItems: responseData.meta.totalItems });
    }
  };

  const handleClickSubmitButton = async () => {
    setIsFetching(true);

    const isSuccess = await onSubmit();
    if (!isSuccess) {
      toast.error("Update round failed!");
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (!data.label || isPublicRound) return;

    handleGetWhitelistAddress();
  }, [
    pagination.pageSize,
    pagination.currentPage,
    data.label,
    isPublicRound,
    selectedStatus.value,
  ]);

  return (
    <Box>
      <Stack
        sx={{
          maxWidth: 608,
        }}
        spacing={5}
      >
        <AppLabel
          label="Label"
          content={
            <AppInput
              value={data.label ?? ""}
              onChange={(e) => {
                onChange({ label: e.target.value });
              }}
              placeholder="Enter Label"
              inputProps={{ maxLength: 6 }} // Fix max length
            />
          }
        />
        <AppLabel
          label="Start"
          content={
            <MobileDateTimePicker
              value={data.start}
              onChange={(value: any) => onChange({ start: value })}
            />
          }
        />
        <AppLabel
          label="End"
          content={
            <MobileDateTimePicker
              value={data.end}
              onChange={(value: any) => onChange({ end: value })}
            />
          }
        />
        {!isPublicRound && (
          <AppLabel
            label="Limit"
            content={<AppInput value={data?.limitAmount || ""} placeholder="Enter Limit" />}
          />
        )}
        <AppLabel
          label="Price"
          content={
            <AppInput
              value={data.price ?? ""}
              onChange={(e) => {
                if (Number.isNaN(Number(e.target.value))) return;

                onChange({ price: e.target.value });
              }}
              placeholder="Enter Price"
            />
          }
        />
        <AppButton
          disabled={isDisabledSubmitButton}
          classes={
            {
              root: classes.button,
            } as ButtonClasses
          }
          onClick={handleClickSubmitButton}
          variant="contained"
        >
          Save
        </AppButton>
      </Stack>
      {!isPublicRound && (
        <Stack>
          <Stack mb={3} mt={6} width="100%" direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems={"center"} spacing={4}>
              <AppTypography
                sx={{
                  fontWeight: 700,
                  fontSize: 24,
                  lineHeight: "32px",
                }}
              >
                Wallet List Address
              </AppTypography>
              <StatusMenu onChange={(data) => setSelectedStatus(data)} options={MENU_DATA} />
            </Stack>
            <Stack direction="row" alignItems="center" spacing={2}>
              <ResendButton/>
              <UploadFileCSV onConfirm={handleSetWhitelist} />
            </Stack>
          </Stack>
          <TableResult
            TablePaginationProps={{
              page: pagination.currentPage,
              rowsPerPage: pagination.pageSize,
              count: pagination.totalItems,

              onPageChange: (_event, currentPage) => handleChangePagination({ currentPage }),
              onRowsPerPageChange: (
                event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => {
                handleChangePagination({ currentPage: 0, pageSize: Number(event.target.value) });
              },
            }}
            tableHeader={TABLE_HEADER}
            tableBodyData={tableRows}
          />
        </Stack>
      )}
    </Box>
  );
};

export default RoundContainer;

const TABLE_HEADER = ["No", "Wallet Address", "Token Send"];

type RoundContainerProps = {
  roundType: AppConstant.ROUND_TYPE;
  data: RoundProps;
  onSubmit: () => Promise<boolean>;
  onChange: (data: KeyAbleProps) => void;
  onUploadCsvSuccess?: (data: TableRow[]) => void;
};

export const useStyles = makeStyles({
  button: {
    width: 124,
    borderRadius: "4px",
    alignSelf: "flex-end",
  },
});

type ResponseWhitelistProps = {
  data: { id: string; wallet: string; hash: string | null }[];
  meta: { totalItems: number };
};

enum WhitelistStatus {
  SEND = "Send",
  NOT_SEND = "Not send",
}

import { AutocompleteProps } from "@mui/material";
import AppHead, { AppHeadProps } from "./AppHead";
import AppLink from "./AppLink";
import AuthenticationRoute from "./AuthenticationRoute";
import AppTypography from "./AppTypography";
import AppInput from "./AppInput";
import AppButton from "./AppButton";
import AppDialog, { AppDialogProps } from "./AppDialog";
import AppLabel from "./AppLabel";
import TableResult from "./TableResult";
import UploadFileCSV from "./UploadFileCSV";
import UploadButton from "./UploadButton";
import AppLoading from "./AppLoading";

export {
  AppLabel,
  AppHead,
  AppLink,
  AuthenticationRoute,
  AppTypography,
  AppInput,
  AppButton,
  AppDialog,
  TableResult,
  UploadButton,
  UploadFileCSV,
  AppLoading,
};

type AppAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
> = AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;

export type { AppHeadProps, AppDialogProps, AppAutocompleteProps };

import React, { ComponentPropsWithoutRef, FC, HTMLAttributes } from "react";
import { AppButton } from "components/common";
import { CandyService } from "services";

const ResendButton: FC<ResendButtonProps> = ({
  ...otherProps
}) => {
  
  const handleResend = () => {
    CandyService.resendToken().then(res => {
      console.log(res);
      alert('Sending in progress... It may take several minutes depeding on network condition.')
    }).catch(e => {
      console.error(e);
    })
  };

  return (
    <AppButton component="label" {...otherProps} onClick={handleResend}>
      Resend
    </AppButton>
  );
};

export default ResendButton;

interface ResendButtonProps extends ComponentPropsWithoutRef<typeof AppButton> {
  inputProps?: HTMLAttributes<HTMLInputElement>;
}

import React, { memo, ReactNode } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TablePagination,
  TablePaginationProps,
  TableRow,
  TableRowProps,
} from "@mui/material";
import { StringOrNumber, ThemeProps } from "models/types";
import { makeStyles } from "@mui/styles";
import { AppConstant } from "const";
import clsx from "clsx";

const TableResult = ({
  tableHeader,
  tableBodyData,
  TableRowProps,
  TablePaginationProps,
  ...otherProps
}: TableResultProps) => {
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableWrapper} {...otherProps}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeader.map((headerName, index) => (
              <TableCell
                key={index}
                align="left"
                sx={{
                  color: "#424242",
                  fontWeight: 700,
                  fontSize: 16,
                  lineHeight: "24px",
                  textAlign: "center",
                }}
              >
                {headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableBodyData.map((bodyItem, index) => (
            <TableRow key={index} {...TableRowProps}>
              {tableHeader.map((_, index) => (
                <TableCell
                  key={index}
                  align="left"
                  sx={{
                    color: "#424242",
                    fontWeight: 400,
                    fontSize: 16,
                    lineHeight: "24px",
                    textAlign: "center",
                  }}
                >
                  {bodyItem[index]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {tableBodyData.length > 0 ? (
        <TablePagination
          sx={{
            "& .MuiTablePagination-selectLabel, & .MuiInputBase-root, & .MuiTablePagination-displayedRows":
              {
                color: "#424242",
                fontWeight: 400,
                fontSize: 16,
                lineHeight: "24px",
              },
          }}
          rowsPerPageOptions={AppConstant.ROW_PER_PAGE_OPTIONS}
          component="div"
          {...TablePaginationProps}
        />
      ) : (
        <EmptyTable />
      )}
    </TableContainer>
  );
};

export type TableRow = (StringOrNumber | boolean)[];
export type TableResultProps = TableContainerProps & {
  tableHeader: string[];
  tableBodyData: ReactNode[][];
  TableRowProps?: TableRowProps;
  TablePaginationProps: TablePaginationProps;
  actionButtons?: { [key: string]: ReactNode[] };
};

export default memo(TableResult);

const EmptyTable = () => {
  const classes = useStyles();
  return <Box className={clsx("center-root", classes.emptyTable)}>Not Found</Box>;
};

const useStyles = makeStyles((theme: ThemeProps) => ({
  tableWrapper: {
    backgroundColor: theme.palette.common.white,
    borderRadius: 4,
  },
  emptyTable: {
    height: 336,
    color: "#424242",
    fontSize: 40,
  },
  emptyRow: {
    height: 57,
    borderBottom: `1px solid`,
    borderColor: theme.palette.grey[300],
  },
  columAction: {
    padding: 8,
  },
}));

import React, { memo } from "react";
import { BoxProps, Button, ButtonClasses, ButtonProps } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProps } from "models/types";
import clsx from "clsx";

const AppButton = ({ classes, children, ...otherProps }: AppButtonProps) => {
  const defaultClasses = useStyles();

  return (
    <Button
      classes={{
        ...classes,
        root: clsx(defaultClasses.root, classes?.root),
        disabled: clsx(defaultClasses.disabled, classes?.disabled),
      }}
      variant="contained"
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export type AppButtonProps = BoxProps &
  ButtonProps & {
    classes?: ButtonClasses;
  };

export default memo(AppButton);

const useStyles = makeStyles((theme: ThemeProps) => ({
  root: {
    minWidth: 135,
    fontSize: 20,
    textTransform: "none",
    background: "linear-gradient(90deg, #32B44A 0%, #007F3F 100%)",
    borderRadius: "4px",
    boxShadow: "none",
    color: theme.palette.common.white,
    fontWeight: "600",
    lineHeight: theme.spacing(3.5),
  },

  disabled: {
    "&&": {
      color: "white",
      background: theme.palette.grey[500],
    },
  },
}));

export const NETWORK_TYPE = {
  mainnet: "mainnet-beta",
  testnet: "testnet",
  devnet: "devnet",
};

export const CHAIN_SUPPORT = {
  solana: "solana",
};

export const POOLING_TRANSACTION_STATUS_TIME_IN_MILLISECOND = 3000;
export const MAXIMUM_POOLING_TRANSACTION_EXECUTE =
  600000 / POOLING_TRANSACTION_STATUS_TIME_IN_MILLISECOND; // 200 time function execute - 10 minutes

export const TRANSACTION_STATUS = {
  failed: 0,
  success: 1,
};

export const DEFAULT_TOKEN_BASE_UNIT = 18;

export const DEFAULT_GAS_LIMIT = 30000;

import React, { memo } from "react";
import { SvgIcon } from "@mui/material";
import { IIconProps } from "models";

const LogoutIcon = ({ sx, ...otherProps }: IIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" sx={{ fontSize: "inherit", ...sx }} {...otherProps}>
      <g clipPath="url(#clip0_743_28778)">
        <path
          d="M9.9668 18.3057H2.49168C2.0332 18.3057 1.66113 17.9336 1.66113 17.4751V2.52492C1.66113 2.06645 2.03324 1.69438 2.49168 1.69438H9.9668C10.4261 1.69438 10.7973 1.32313 10.7973 0.863828C10.7973 0.404531 10.4261 0.0332031 9.9668 0.0332031H2.49168C1.11793 0.0332031 0 1.15117 0 2.52492V17.4751C0 18.8488 1.11793 19.9668 2.49168 19.9668H9.9668C10.4261 19.9668 10.7973 19.5955 10.7973 19.1362C10.7973 18.6769 10.4261 18.3057 9.9668 18.3057Z"
          fill="white"
        />
        <path
          d="M19.7525 9.40864L14.7027 4.42524C14.3771 4.10298 13.8505 4.10716 13.5282 4.43356C13.206 4.75997 13.2093 5.28571 13.5366 5.60798L17.1453 9.16942H7.47508C7.01578 9.16942 6.64453 9.54067 6.64453 9.99997C6.64453 10.4593 7.01578 10.8306 7.47508 10.8306H17.1453L13.5366 14.392C13.2093 14.7143 13.2068 15.24 13.5282 15.5664C13.6055 15.6448 13.6976 15.7071 13.7991 15.7495C13.9006 15.792 14.0096 15.8139 14.1196 15.8139C14.3306 15.8139 14.5415 15.7342 14.7027 15.5747L19.7525 10.5913C19.8308 10.514 19.893 10.4219 19.9355 10.3204C19.978 10.2189 19.9999 10.11 20 9.99993C20 9.77743 19.9111 9.56563 19.7525 9.40864Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_743_28778">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};

export default memo(LogoutIcon);
